import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import slot from './slot'
import subject from './subject'
import attendance from './attendance'
import live from './live'
import menu from './menu'
import sesi from './sesi'
import admin from './admin'
import cbt from './cbt'
import report from './report'
import homeroom from './homeroom'
import curriculum from './curriculum'
import extracurricular from './extracurricular'
import schoolYear from './schoolYear'
import studentClass from './studentClass'
import master from './master'
import ppdb from './ppdb'
import announcement from './announcement'
import bankSoal from './bankSoal'
import paketSoal from './paketSoal'
import counseling from './counseling'
// import shift from './shift'
// import absensiKaryawan from './absensiKaryawan'
// import kegiatan from './kegiatan'
// import cuti from './cuti'
// import izin from './izin'
// import insentif from './insentif'
// import adminHR from './adminHR'
// import adminKeuangan from './adminKeuangan'
import config from '@/config.js'
import axios from 'axios'

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import FontPlugin from '@ckeditor/ckeditor5-font/src/font'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
// import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
// import MathType from '@wiris/mathtype-ckeditor5/src/plugin'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    settings,
    slot,
    subject,
    attendance,
    sesi,
    live,
    menu,
    admin,
    cbt,
    report,
    homeroom,
    curriculum,
    extracurricular,
    master,
    schoolYear,
    studentClass,
    ppdb,
    announcement,
    bankSoal,
    paketSoal,
    counseling,
    // HR FINANCE FEATURES
    // shift,
    // absensiKaryawan,
    // kegiatan,
    // cuti,
    // izin,
    // insentif,
    // adminHR,
    // adminKeuangan,
  },
  state: {
    isOnSEB: navigator.userAgent.indexOf('SEB') !== -1,
    // isOnSEB: true,
    isLoading: false,
    cbtType: '',
    ckEditor: {
      editor: ClassicEditor,
      editorConfig: {
        allowedContent: true,
        removeFormatAttributes: '',
        plugins: [
          Alignment,
          EssentialsPlugin,
          Bold,
          Italic,
          Underline,
          Strikethrough,
          Subscript,
          Superscript,
          LinkPlugin,
          ParagraphPlugin,
          ListStyle,
          FontPlugin,
          Heading,
          Table,
          TableToolbar,
          BlockQuote,
          SimpleUploadAdapter,
          Image,
          ImageToolbar,
          ImageUpload,
          ImageResize,
          ImageStyle,
          LinkImage,
          // MathType,
        ],
        // alignment: {
        //   options: ['left', 'right'],
        // },
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'fontColor',
            '|',
            // 'MathType',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'imageUpload',
            'link',
            'undo',
            'redo',
            '|',
            'insertTable',
            'tableColumn',
            'tableRow',
            'mergeTableCells',
          ],
        },
        link: {
          addTargetToExternalLinks: true,
          decorators: [
            {
              // mode: 'manual',
              // label: 'Downloadable',
              // attributes: {
              //   download: 'download',
              // },
            },
          ],
        },
        image: {
          resizeOptions: [
            {
              name: 'imageResize:75',
              label: '75%',
              value: '75',
            },
            {
              name: 'imageResize:original',
              label: 'Original',
              value: null,
            },
            {
              name: 'imageResize:50',
              label: '50%',
              value: '50',
            },
          ],
          styles: ['alignLeft', 'alignCenter', 'alignRight', 'full', 'side'],
          toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'imageResize',
            '|',
            'imageTextAlternative',
            'linkImage',
            '|',
          ],
          // upload: {
          //   types: ['png', 'jpeg', 'jpg'],
          // },
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: config.apiUrl + '/api/topik/upload-image',

          // Enable the XMLHttpRequest.withCredentials property.
          // withCredentials: true,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            'X-CSRF-TOKEN': 'CSRF-Token',
            token: localStorage.token,
            api: config.apiUrl,
          },
        },
      },
    },
    uploadedImages: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    UNLINK_IMAGE(_, { unlinkImages }) {
      axios(config.apiUrl + '/api/topik/unlink-image', {
        method: 'post',
        data: {
          unlink_images: unlinkImages,
        },
        headers: {
          token: localStorage.token,
        },
      })
    },
  },
  getters: {
    // isMobile: state => {
    //   return state.isMobileView || JSON.parse(localStorage.getItem('app.settings.isMobileView'))
    // },
    // isTablet: state => {
    //   return state.isTabletView || JSON.parse(localStorage.getItem('app.settings.isTabletView'))
    // },
    cbtType: (state) => {
      return state.cbtType || localStorage.cbtType
    },
  },
})
